import React from "react"
import "./QuoteAuthorImage.scss"
import QuoteAuthorImageBackground from "./QuoteAuthorImageBackground/QuoteAuthorImageBackground"
import QuoteAuthorImageForeground from "./QuoteAuthorImageForeground/QuoteAuthorImageForeground"
import QuoteAuthorImageQuoteIcon from "./QuoteAuthorImageQuoteIcon/QuoteAuthorImageQuoteIcon"
import Img from "components/Img/Img"

const QuoteAuthorImage = ({
    author,
    image,
    swatch
}) => {
    return (
        <div className="Quote__author-image">
            <Img alt={author}
                image={image}
                swatch={swatch}
            />

            <QuoteAuthorImageBackground swatch={swatch} />

            <QuoteAuthorImageForeground />

            <QuoteAuthorImageQuoteIcon swatch={swatch} />
        </div>
    )
}

export default QuoteAuthorImage