import React, { Fragment, useState } from "react"
import "./Home.scss"
import { Helmet } from "react-helmet"
import HomePost from "./HomePost/HomePost"
import PageLoading from "components/PageLoading/PageLoading"
import SocialLinks from "components/SocialLinks/SocialLinks"
import Img from "components/Img/Img"

const Home = ({ postsData }) => {
    const [posts] = useState(postsData)
    const [numberOfIntersectedPosts, setNumberOfIntersectedPosts] = useState(0)

    const incrementNumberOfIntersectedPosts = () => setNumberOfIntersectedPosts(numberOfIntersectedPosts + 1)

    if (posts === null) return <PageLoading />

    const differentLogoImage = {
        "currentGen": {
            "source": {
                "lazy": "https://res.cloudinary.com/different-network/image/upload/v1623657174/different.ma/different/different--lazy-current.png",
                "full": "https://res.cloudinary.com/different-network/image/upload/v1623657175/different.ma/different/different_3840-current.png"
            },
            "mime": "image/png"
        },
        "nextGen": {
            "source": {
                "lazy": "https://res.cloudinary.com/different-network/image/upload/v1623657174/different.ma/different/different--lazy-next.webp",
                "full": "https://res.cloudinary.com/different-network/image/upload/v1623657174/different.ma/different/different_3840-next.webp"
            },
            "mime": "image/webp"
        },
        "aspectRatio": "16.04%"
    }

    const differentNetworkLogoImage = {
        "currentGen": {
            "source": {
                "lazy": "https://res.cloudinary.com/different-network/image/upload/v1623679235/different.ma/different/different-network--lazy-current.png",
                "full": "https://res.cloudinary.com/different-network/image/upload/v1623679235/different.ma/different/different-network_720-current.png"
            },
            "mime": "image/png"
        },
        "nextGen": {
            "source": {
                "lazy": "https://res.cloudinary.com/different-network/image/upload/v1623679234/different.ma/different/different-network--lazy-next.webp",
                "full": "https://res.cloudinary.com/different-network/image/upload/v1623679234/different.ma/different/different-network_720-next.webp"
            },
            "mime": "image/webp"
        },
        "aspectRatio": "100.00%"
    }

    const easyQualLogoImage = {
        "currentGen": {
            "source": {
                "lazy": "https://res.cloudinary.com/different-network/image/upload/v1623680826/different.ma/different/easyqual--lazy-current.png",
                "full": "https://res.cloudinary.com/different-network/image/upload/v1623680826/different.ma/different/easyqual.png"
            },
            "mime": "image/png"
        },
        "nextGen": {
            "source": {
                "lazy": "https://res.cloudinary.com/different-network/image/upload/v1623680826/different.ma/different/easyqual--lazy-next.webp",
                "full": "https://res.cloudinary.com/different-network/image/upload/v1623680826/different.ma/different/easyqual-next.webp"
            },
            "mime": "image/webp"
        },
        "aspectRatio": "100.00%"
    }

    const banPaperLogoImage = {
        "currentGen": {
            "source": {
                "lazy": "https://res.cloudinary.com/different-network/image/upload/v1623681296/different.ma/different/ban-paper--lazy-current.png",
                "full": "https://res.cloudinary.com/different-network/image/upload/v1623681296/different.ma/different/ban-paper_700-current.png"
            },
            "mime": "image/png"
        },
        "nextGen": {
            "source": {
                "lazy": "https://res.cloudinary.com/different-network/image/upload/v1623681296/different.ma/different/ban-paper--lazy-next.webp",
                "full": "https://res.cloudinary.com/different-network/image/upload/v1623681296/different.ma/different/ban-paper_700-next.webp"
            },
            "mime": "image/webp"
        },
        "aspectRatio": "100.00%"
    }

    const aSwatch = {
        "vibrant": "rgb(228, 180, 52)",
        "darkVibrant": "rgb(132, 100, 28)",
        "lightVibrant": "rgb(239.4339130434782, 211.76086956521735, 137.9660869565218)",
        "muted": "rgb(124, 132, 124)",
        "darkMuted": "rgb(48, 76, 80)",
        "lightMuted": "rgb(188, 188, 188)"
    }

    const handleScrollClick = () => document.querySelector("main").scrollIntoView({ behavior: "smooth" })

    return (
        <Fragment>
            <Helmet>
                <title>Different</title>
                <meta name="description" content="Different software engineering company located in Tangier, Morocco. We are working on some interesting projects behind the scenes, but we also do programming for clients." />
                <meta name="theme-color" content="#000000" />
            </Helmet>

            <div className="Home">
                <header className="Home__header">
                    <div className="Home__header__image">
                        <Img alt="Different's logo"
                            image={differentLogoImage}
                            swatch={aSwatch}
                        />
                    </div>

                    <p>Design & Development</p>

                    <button onClick={handleScrollClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFF">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M19 15l-1.41-1.41L13 18.17V2h-2v16.17l-4.59-4.59L5 15l7 7 7-7z" />
                        </svg>
                    </button>
                </header>

                <main className="Home__main">
                    <h2>about_us<span>|</span></h2>
                    <section className="Home__main__about-us">
                        <p className="gradient">
                            We are software design & engineering company based in Tangier, Morocco
                        </p>
                    </section>
                    <h2>our_projects<span>|</span></h2>
                    <section className="Home__main__our-projects">
                        <div className="Home__main__our-projects__project">
                            <div className="Home__main__our-projects__project__image">
                                <Img alt="different network's logo"
                                    image={differentNetworkLogoImage}
                                    swatch={{aSwatch}}
                                />
                            </div>
                            <h3 className="gradient">
                                Different Network
                            </h3>
                            <p>A social media platform that aims to popularize sharing information and educating others. The platform will be built using modern, cutting edge technology.</p>
                        </div>
                        <div className="Home__main__our-projects__project">
                            <div className="Home__main__our-projects__project__image">
                                <Img alt="easyQual's logo"
                                    image={easyQualLogoImage}
                                    swatch={{aSwatch}}
                                />
                            </div>
                            <h3 className="gradient">
                                EasyQual
                            </h3>
                            <p>An electronic document management software dedicated to quality management within companies. </p>
                        </div>
                        <div className="Home__main__our-projects__project">
                            <div className="Home__main__our-projects__project__image">
                                <Img alt="ban paper's logo"
                                    image={banPaperLogoImage}
                                    swatch={{aSwatch}}
                                />
                            </div>
                            <h3 className="gradient">
                                Ban Paper
                            </h3>
                            <p>A support service for companies allowing them to gradually break free of physical printed paper, saving space and money while having peace of mind.</p>
                        </div>
                    </section>
                    <h2>services<span>|</span></h2>
                    <section className="Home__main__services">
                        <ul>
                            <li className="gradient">Design</li>
                            <li>UI UX Design</li>
                            <li>Branding</li>
                            <li>Print</li>
                            <li>Packaging</li>
                        </ul>
                        <ul>
                            <li className="gradient">Development</li>
                            <li>Web Apps</li>
                            <li>APIs</li>
                            <li>Browser Extensions</li>
                            <li>Database Modelling</li>
                        </ul>
                        <ul>
                            <li className="gradient">E-commerce</li>
                            <li>Automation</li>
                            <li>Research Tools</li>
                            <li>Copywriting</li>
                            <li>Marketing</li>
                        </ul>
                    </section>
                    <h2>client_projects<span>|</span></h2>
                    <section className="Home__main__client-projects">
                        {
                            posts.map((post, index) => {
                                return (
                                    <HomePost key={index}
                                        post={post}
                                        incrementNumberOfIntersectedPosts={incrementNumberOfIntersectedPosts}
                                    />
                                )
                            })
                        }
                    </section>
                </main>

                <footer>
                    <SocialLinks />
                </footer>
            </div>
        </Fragment>
    )
}

export default Home