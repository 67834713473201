import React, { useEffect } from "react"
import "./ArticleHeader.scss"
import ArticleHeaderCover from "./ArticleHeaderCover/ArticleHeaderCover"
import ArticleHeaderTitle from "./ArticleHeaderTitle/ArticleHeaderTitle"
import ArticleHeaderFade from "./ArticleHeaderFade/ArticleHeaderFade"

const ArticleHeader = ({ cover, mainIntersected, swatch, title }) => {
    useEffect(() => {
        setTimeout(() => {
            const scrollTop = (window.pageYOffset !== undefined)
                ? window.pageYOffset
                : (document.documentElement || document.body.parentNode || document.body).scrollTop
            if (scrollTop === 0) window.scrollTo({ behavior: "smooth", top: window.innerHeight * 0.05 })
        }, 500)
    }, [])

    return (
        <header className="Article__header">
            <ArticleHeaderCover cover={cover}
                mainIntersected={mainIntersected}
                swatch={swatch}
                title={title}
            />

            <ArticleHeaderFade mainIntersected={mainIntersected}
                swatch={swatch}
            />

            <ArticleHeaderTitle mainIntersected={mainIntersected} title={title} />
        </header>
    )
}

export default ArticleHeader