import React, { Fragment, useState } from "react"
import "./Cover.scss"
import CoverPlaceholder from "./CoverPlaceholder/CoverPlaceholder"
import CoverLazy from "./CoverLazy/CoverLazy"
import CoverFull from "./CoverFull/CoverFull"
import Loader from "components/Loader/Loader"

const Cover = ({ alt, cover, mainIntersected, swatch }) => {
    const [coverLoaded, setCoverLoaded] = useState(false)
    const [coverLazyLoaded, setCoverLazyLoaded] = useState(false)

    return (
        <Fragment>
            <CoverPlaceholder swatch={swatch} />

            <CoverLazy cover={cover}
                coverLazyLoaded={coverLazyLoaded}
                setCoverLazyLoaded={setCoverLazyLoaded}
            />

            <CoverFull alt={alt}
                cover={cover}
                coverLazyLoaded={coverLazyLoaded}
                coverLoaded={coverLoaded}
                setCoverLoaded={setCoverLoaded}
            />

            <Loader className="Cover__loader"
                visible={!coverLoaded && !mainIntersected}
            />
        </Fragment>
    )
}

export default Cover