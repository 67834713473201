import React from "react"
import { Helmet } from "react-helmet"

const PostHead = ({ darkVibrant, description, title }) => (
    <Helmet>
        <title>{title} - Different</title>
        <meta name="description" content={description} />
        <meta name="theme-color" content={darkVibrant} />
    </Helmet>
)

export default PostHead