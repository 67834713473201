import React, { useRef } from "react"
import "./FigureCaption.scss"
import useContentEditable from "hooks/useContentEditable"

const FigureCaption = ({ caption, editor, handleEditFigureCaption, index }) => {
    const ref = useRef()

    const [updateCaretPosition] = useContentEditable(ref, caption)

    if (!caption) return null

    const handleInput = event => {
        handleEditFigureCaption(index, event.target.innerText)

        updateCaretPosition()
    }

    return (
        <figcaption className="Figure__caption"
            ref={ref}
            // Editor
            contentEditable={editor || false}
            onInput={handleInput}
            suppressContentEditableWarning={editor || false}>
            {caption}
        </figcaption>
    )
}

export default FigureCaption