import React from "react"
import "./ImgFull.scss"

const ImgFull = ({
    alt,
    image,
    imageLazyLoaded,
    imageLoaded,
    setImageLoaded
}) => {
    if (!imageLazyLoaded) return null

    return (
        <picture className={`Img__full loaded--${imageLoaded}`}>
            <source srcSet={image.nextGen.source.full}
                type={image.nextGen.mime}
            />

            <source srcSet={image.currentGen.source.full}
                type={image.currentGen.mime}
            />

            <img alt={alt}
                onLoad={() => setImageLoaded(true)}
                src={image.currentGen.source.full}
            />
        </picture>
    )
}

export default ImgFull