import React from "react"
import "./Highlight.scss"
import getTransparentColor from "functions/getTransparentColor"

const Highlight = ({ text, swatch }) => {
    return (
        <mark className="Highlight"
            style={{ backgroundImage: `linear-gradient(to right, ${getTransparentColor(swatch.lightVibrant, 0.7)}, ${getTransparentColor(swatch.vibrant, 0.7)})` }}>
            {text}
        </mark>
    )
}

export default Highlight