import React from "react"
import "./FigureVideo.scss"
import Video from "components/Video/Video"

const FigureVideo = ({ swatch, video }) => {
    return (
        <div className="Figure__video">
            <Video swatch={swatch}
                video={video}
            />
        </div>
    )
}

export default FigureVideo