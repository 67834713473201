import React, { useEffect, useState } from "react"
import ScrollContext from "./ScrollContext"

const ScrollContextProvider = ({ children }) => {
    const [pageYOffset, setPageYOffset] = useState(window.pageYOffset)

    useEffect(() => {
        const handleScroll = () => setPageYOffset(window.pageYOffset)

        window.addEventListener("scroll", handleScroll)

        return () => window.removeEventListener("scroll", handleScroll)
    }, [])

    return (
        <ScrollContext.Provider value={{ pageYOffset }}>
            {children}
        </ScrollContext.Provider>
    )
}

export default ScrollContextProvider