import React, { useRef } from "react"
import "./Heading.scss"
import useContentEditable from "hooks/useContentEditable"
import useIntersectionObserver from "hooks/useIntersectionObserver"

const Heading = ({
    children,
    referenceId,
    // Editor
    editor,
    handleEditHeading,
    index
}) => {
    const ref = useRef()

    const [updateCaretPosition] = useContentEditable(ref, children)
    const intersected = useIntersectionObserver(ref)

    const handleInput = event => {
        handleEditHeading(index, event.target.innerText)

        updateCaretPosition()
    }

    return (
        <h2 className={`Heading intersected--${intersected}`}
            id={referenceId ? `${referenceId}` : null}
            ref={ref}
            // Editor
            contentEditable={editor || false}
            onInput={handleInput}
            suppressContentEditableWarning={editor || false}>
            {children}
        </h2>
    )
}

export default Heading