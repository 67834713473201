import React from "react"
import "./CoverLazy.scss"

const CoverLazy = ({ cover, coverLazyLoaded, setCoverLazyLoaded }) => {
    return (
        <picture className={`Cover__lazy loaded--${coverLazyLoaded}`}>
            <source srcSet={cover.nextGen.landscapeSource.lazy}
                media="(orientation: landscape)"
                type={cover.nextGen.mime}
            />

            <source srcSet={cover.currentGen.landscapeSource.lazy}
                media="(orientation: landscape)"
                type={cover.currentGen.mime}
            />

            <source srcSet={cover.nextGen.portraitSource.lazy}
                media="(orientation: portrait)"
                type={cover.nextGen.mime}
            />

            <source srcSet={cover.currentGen.portraitSource.lazy}
                media="(orientation: portrait)"
                type={cover.currentGen.mime}
            />

            <img alt=""
                onLoad={() => setCoverLazyLoaded(true)}
                src={cover.currentGen.landscapeSource.lazy}
            />
        </picture>
    )
}

export default CoverLazy