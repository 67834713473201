import React from "react"
import "./ImgPlaceholder.scss"

const ImgPlaceholder = ({ aspectRatio, mime, reference, swatch }) => {
    if (!swatch) return null

    let style = {}

    if (mime !== "image/png") style.backgroundImage = `
        radial-gradient(ellipse at top, ${swatch.lightVibrant}, ${swatch.vibrant}, ${swatch.darkVibrant}),
        radial-gradient(ellipse at bottom, ${swatch.lightMuted}, ${swatch.muted}, ${swatch.darkMuted})  
    `

    if (aspectRatio) style.paddingBottom = aspectRatio
    else style.height = "100%"

    return (
        <div className="Img__placeholder"
            ref={reference}
            style={style}>
        </div>
    )
}

export default ImgPlaceholder