import React from "react"
import Figure from "./Figure/Figure"
import Heading from "./Heading/Heading"
import Paragraph from "./Paragraph/Paragraph"
import PreformattedCode from "./PreformattedCode/PreformattedCode"
import Quote from "./Quote/Quote"
import TableOfContents from "./TableOfContents/TableOfContents"

const useBlocks = (
    blocks,
    swatch,
    // Editor
    editor,
    handleEditHeading,
    handleEditFigureCaption,
    handleSelection
) => blocks.map((block, index) => {
    if (block.type === "code") return (
        <PreformattedCode key={index} swatch={swatch}>
            {block.content}
        </PreformattedCode>
    )

    else if (block.type === "figure") return (
        <Figure caption={block.content.caption}
            image={block.content.image}
            key={index}
            swatch={block.content.swatch}
            type={block.content.type}
            video={block.content.video}
            // Editor
            editor={editor}
            handleEditFigureCaption={handleEditFigureCaption}
            index={index}
        />
    )

    else if (block.type === "heading") return (
        <Heading key={index}
            referenceId={block.referenceId}
            // Editor
            editor={editor}
            handleEditHeading={handleEditHeading}
            index={index}>
            {block.content}
        </Heading>
    )

    else if (block.type === "paragraph") return (
        <Paragraph key={index}
            swatch={swatch}
            // Editor
            editor={editor}
            handleSelection={handleSelection}
            index={index}>
            {block.content}
        </Paragraph>
    )

    else if (block.type === "quote") return (
        <Quote author={block.content.author}
            cite={block.content.cite}
            citeURL={block.content.citeURL}
            image={block.content.image}
            key={index}
            swatch={swatch}
            // Editor
            editor={editor}
            index={index}>
            {block.content.quote}
        </Quote>
    )

    else if (block.type === "table-of-contents") return (
        <TableOfContents blocks={blocks}
            key={index}
            order={index}
            swatch={swatch}
            // Editor
            editor={editor}
            index={index}
        />
    )

    return null
})

export default useBlocks