import React, { useRef } from "react"
import "./SocialLinks.scss"
import useIntersectionObserver from "hooks/useIntersectionObserver"

const SocialLinks = () => {
    const ref = useRef()

    const intersected = useIntersectionObserver(ref)

    return (
        <div className={`SocialLinks intersected--${intersected}`} ref={ref}>
            <a aria-label="facebook"
                className="SocialLinks__icon SocialLinks__icon--facebook"
                href="https://www.facebook.com/Different.Network/"
                rel="noopener noreferrer nofollow"
                target="_blank">
                <svg viewBox="0 0 24 24">
                    <path d="M17 2v4h-2c-.69 0-1 .81-1 1.5V10h3v4h-3v8h-4v-8H7v-4h3V6a4 4 0 014-4h3z"/>
                </svg>
            </a>

            <a aria-label="twitter"
                className="SocialLinks__icon SocialLinks__icon--twitter"
                href="https://twitter.com/differentnet"
                rel="noopener noreferrer nofollow"
                target="_blank">
                <svg viewBox="0 0 24 24">
                    <path d="M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21-.36.1-.74.15-1.13.15-.27 0-.54-.03-.8-.08.54 1.69 2.11 2.95 4 2.98-1.46 1.16-3.31 1.84-5.33 1.84-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z"/>
                </svg>
            </a>

            <a aria-label="instagram"
                className="SocialLinks__icon SocialLinks__icon--instagram"
                href="https://www.instagram.com/differentnetwork/"
                rel="noopener noreferrer nofollow"
                target="_blank">
                <svg viewBox="0 0 24 24">
                    <defs>
                        <radialGradient id="instagram-gradient" r="150%" cx="30%" cy="107%">
                            <stop stopColor="#fdf497" offset="0" />
                            <stop stopColor="#fdf497" offset="0.05" />
                            <stop stopColor="#fd5949" offset="0.45" />
                            <stop stopColor="#d6249f" offset="0.6" />
                            <stop stopColor="#285AEB" offset="0.9" />
                        </radialGradient>
                    </defs>

                    <path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 01-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 017.8 2m-.2 2A3.6 3.6 0 004 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 003.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 011.25 1.25A1.25 1.25 0 0117.25 8 1.25 1.25 0 0116 6.75a1.25 1.25 0 011.25-1.25M12 7a5 5 0 015 5 5 5 0 01-5 5 5 5 0 01-5-5 5 5 0 015-5m0 2a3 3 0 00-3 3 3 3 0 003 3 3 3 0 003-3 3 3 0 00-3-3z"/>
                </svg>
            </a>
        </div>
    )
}

export default SocialLinks