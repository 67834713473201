import React from "react"
import "./HomePostLazy.scss"

const HomePostLazy = ({ cover, coverLazyLoaded, setCoverLazyLoaded }) => {
    return (
        <picture className={`Home__post__lazy loaded--${coverLazyLoaded}`}>
            <source srcSet={cover.nextGen.portraitSource.lazy}
                type={cover.nextGen.mime}
            />

            <source srcSet={cover.currentGen.portraitSource.lazy}
                type={cover.currentGen.mime}
            />

            <img alt=""
                onLoad={() => setCoverLazyLoaded(true)}
                src={cover.currentGen.portraitSource.lazy}
            />
        </picture>
    )
}

export default HomePostLazy