import React, { useRef } from "react"
import "./TableOfContents.scss"
import useIntersectionObserver from "hooks/useIntersectionObserver"

const TableOfContents = ({ blocks, order, swatch }) => {
    const ref = useRef()

    const intersected = useIntersectionObserver(ref)

    const blocksAfterTableOfContents = blocks.slice(order)
    const headingsAfterTableOfContents = blocksAfterTableOfContents.filter(block => block.type === "heading")

    const handleClick = (event, heading) => {
        event.preventDefault()

        document.getElementById(heading.referenceId).scrollIntoView({ behavior: "smooth" })
    }

    return (
        <ol className={`TableOfContents intersected--${intersected}`} ref={ref}>
            {
                headingsAfterTableOfContents.map((heading, index) => {
                    return (
                        <li key={index}>
                            <a href={`#${heading.referenceId}`}
                                onClick={event => handleClick(event, heading)}
                                style={{ backgroundImage: `linear-gradient(to right, ${swatch.lightVibrant}, ${swatch.vibrant})` }}>
                                ~ {heading.content}
                            </a>
                        </li>
                    )
                })
            }
        </ol>
    )
}

export default TableOfContents