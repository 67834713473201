import { useLayoutEffect, useState } from "react"

const useContentEditable = (ref, dependency) => {
    const [caretPosition, setCaretPosition] = useState(0)

    const updateCaretPosition = () => {
        const selection = document.getSelection()

        const { startOffset } = selection.getRangeAt(0)

        setCaretPosition(startOffset)
    }

    useLayoutEffect(() => {
        const range = document.createRange()
        range.setStart(ref.current.childNodes[0], caretPosition)
        range.collapse(true)

        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)

        ref.current.focus()
    }, [dependency, caretPosition, ref])

    return [updateCaretPosition]
}

export default useContentEditable