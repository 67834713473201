import React from "react"
import "./VideoPlaceholder.scss"

const VideoPlaceholder = ({ aspectRatio, swatch }) => {
    if (!swatch) return null

    let style = {}

    style.backgroundImage = `
        radial-gradient(ellipse at top, ${swatch.lightVibrant}, ${swatch.vibrant}, ${swatch.darkVibrant}),
        radial-gradient(ellipse at bottom, ${swatch.lightMuted}, ${swatch.muted}, ${swatch.darkMuted})  
    `

    if (aspectRatio) style.paddingBottom = aspectRatio
    else style.height = "100%"

    return (
        <div className="Video__placeholder"
            style={style}>
        </div>
    )
}

export default VideoPlaceholder