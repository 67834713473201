import React from "react"
import "./HomePostFade.scss"

const HomePostFade = ({ swatch }) => {
    return (
        <div className="Home__post__fade"
            style={{
                background: `linear-gradient(
                    to top,
                    ${swatch.vibrant.replace("rgb", "rgba").replace(")", "").concat(", 0.5)")} 0%,
                    ${swatch.muted.replace("rgb", "rgba").replace(")", "").concat(", 0.5)")} 50%,
                    rgba(0, 0, 0, 0) 100%
                )`
            }}>
        </div>
    )
}

export default HomePostFade