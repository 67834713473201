import React, { useRef } from "react"
import "./Article.scss"
import ArticleHeader from "./ArticleHeader/ArticleHeader"
import ArticleMain from "./ArticleMain/ArticleMain"
import useIntersectionObserver from "hooks/useIntersectionObserver"

const Article = ({ article }) => {
    const mainRef = useRef()

    const mainIntersected = useIntersectionObserver(mainRef, false)

    return (
        <article className="Article">
            <ArticleHeader cover={article.cover}
                mainIntersected={mainIntersected}
                swatch={article.swatch}
                title={article.title}
            />

            <ArticleMain blocks={article.content.blocks}
                mainRef={mainRef}
                swatch={article.swatch}
            />
        </article>
    )
}

export default Article