import React from "react"
import "./HomePostPlaceholder.scss"

const HomePostPlaceholder = ({ swatch }) => {
    return (
        <div className="Home__post__placeholder"
            style={{
                backgroundImage: `
                    radial-gradient(ellipse at top, ${swatch.lightVibrant}, ${swatch.vibrant}, ${swatch.darkVibrant}),
                    radial-gradient(ellipse at bottom, ${swatch.lightMuted}, ${swatch.muted}, ${swatch.darkMuted})
                `
        }}>
        </div>
    )
}

export default HomePostPlaceholder