import React, { useEffect, useState } from "react"
import WindowDimensionsContext from "./WindowDimensionsContext"

const WindowDimensionsContextProvider = ({ children }) => {
    const getDimensions = () => ({
        height: window.innerHeight,
        orientation: (window.innerWidth / window.innerHeight) >= 1 ? "landscape": "portrait",
        width: window.innerWidth
    })

    const [dimensions, setDimensions] = useState(getDimensions())

    useEffect(() => {
        const handleResize = () => {
            setDimensions(getDimensions())
        }

        window.addEventListener("resize", handleResize)

        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return (
        <WindowDimensionsContext.Provider value={{ ...dimensions }}>
            {children}
        </WindowDimensionsContext.Provider>
    )
}

export default WindowDimensionsContextProvider