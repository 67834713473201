import React from "react"
import "./HomePostContentType.scss"
import getArabicPostType from "functions/getArabicPostType"
import getTransparentColor from "functions/getTransparentColor"

const HomePostContentType = ({ swatch, type }) => {
    return (
        <h4 className={`Home__post__content__type Home__post__content__type--${type}`}>
            <span style={{
                background: `linear-gradient(
                    to left,
                    ${getTransparentColor(swatch.vibrant, 0.5)},
                    ${getTransparentColor(swatch.lightVibrant, 0.5)}
                `
            }}>
                {getArabicPostType(type)}
            </span>
        </h4>
    )
}

export default HomePostContentType