import React from "react"
import "./ImgLazy.scss"

const ImgLazy = ({
    image,
    imageLazyLoaded,
    imageLoaded,
    intersected,
    setImageLazyLoaded
}) => {
    if (!intersected) return null

    let style = {}

    if (image.currentGen.mime === "image/png") style.visibility = "hidden"

    return (
        <picture className={`Img__lazy loaded--${imageLazyLoaded} image-full-loaded--${imageLoaded}`} style={style}>
            <source srcSet={image.nextGen.source.lazy}
                type={image.nextGen.mime}
            />

            <source srcSet={image.currentGen.source.lazy}
                type={image.currentGen.mime}
            />

            <img alt=""
                onLoad={() => setImageLazyLoaded(true)}
                src={image.currentGen.source.lazy}
            />
        </picture>
    )
}

export default ImgLazy