import React from "react"

const PostThemedSelection = ({ theme }) => {
    const __html = `
        ::selection {
            background-color: ${theme};
        }
    `

    return (
        <style dangerouslySetInnerHTML={{ __html }} />
    )
}

export default PostThemedSelection