import React from "react"
import "./QuoteAuthorImageQuoteIcon.scss"

const QuoteAuthorImageQuoteIcon = ({ swatch }) => {
    return (
        <svg className="Quote__author-image__quote-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <defs>
                <linearGradient id="quote-icon-gradient">
                    <stop stopColor={swatch.lightVibrant} offset="0%"/>
                    <stop stopColor={swatch.vibrant} offset="100%"/> 
                </linearGradient>
            </defs>

            <path d="M9 9V2h7v7.1c0 4.8-4.5 5.4-4.5 5.4l-.6-1.4s2-.3 2.4-1.9c.4-1.2-.4-2.2-.4-2.2H9zM0 9V2h7v7.1c0 4.8-4.5 5.4-4.5 5.4l-.6-1.4s2-.3 2.4-1.9C4.7 10 3.9 9 3.9 9H0z"/>
        </svg>
    )
}

export default QuoteAuthorImageQuoteIcon