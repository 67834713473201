import React, { Fragment, useRef, useState } from "react"
import ImgPlaceholder from "./ImgPlaceholder/ImgPlaceholder"
import ImgLazy from "./ImgLazy/ImgLazy"
import ImgFull from "./ImgFull/ImgFull"
import useIntersectionObserver from "hooks/useIntersectionObserver"

const Img = ({
    alt,
    image,
    swatch
}) => {
    const [imageLoaded, setImageLoaded] = useState(false)
    const [imageLazyLoaded, setImageLazyLoaded] = useState(false)

    const ref = useRef()

    const intersected = useIntersectionObserver(ref)

    return (
        <Fragment>
            <ImgPlaceholder aspectRatio={image?.aspectRatio}
                mime={image.currentGen.mime}
                reference={ref}
                swatch={swatch}
            />

            <ImgLazy image={image}
                imageLazyLoaded={imageLazyLoaded}
                imageLoaded={imageLoaded}
                intersected={intersected}
                setImageLazyLoaded={setImageLazyLoaded}
            />

            <ImgFull alt={alt}
                image={image}
                imageLazyLoaded={imageLazyLoaded}
                imageLoaded={imageLoaded}
                setImageLoaded={setImageLoaded}
            />
        </Fragment>
    )
}

export default Img