import React from "react"
import "./VideoLazy.scss"

const VideoLazy = ({ setVideoLazyLoaded, video, videoLazyLoaded }) => {
    return (
        <video autoPlay={true}
            className={`Video__lazy loaded--${videoLazyLoaded}`}
            loop={true}
            muted={true}
            onCanPlayThrough={() => setVideoLazyLoaded(true)}>
            {/* <source src={video.nextGen.source.lazy}
                type={video.nextGen.mime}
            /> */}

            <source src={video.currentGen.source.lazy}
                type={video.currentGen.mime}
            />

            متصفحك لا يدعم فيديو HTML5
        </video>
    )
}

export default VideoLazy