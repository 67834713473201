import React from "react"
import "./ArticleHeaderTitle.scss"
import { animated, useTransition } from "react-spring"

const ArticleHeaderTitle = ({ mainIntersected, title }) => {
    const transitions = useTransition(
        mainIntersected,
        null,
        {
            from: { opacity: 0, transform: "translateY(2rem)" },
            enter: { opacity: 1, transform: "translateY(0)" },
            leave: { opacity: 0, transform: "translateY(2rem)" }
        }
    )

    return transitions.map(({ item, key, props }) => {
        if (!item) return null

        return (
            <animated.div className="Article__header__title"
                key={key}
                style={props}>
                <h1>{title}</h1>
            </animated.div>
        )
    })
}

export default ArticleHeaderTitle