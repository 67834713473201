import React from "react"
import "./ArticleHeaderCover.scss"
import Cover from "components/Cover/Cover"

const ArticleHeaderCover = ({
    cover,
    mainIntersected,
    swatch,
    title
}) => {
    return (
        <div className="Article__header__cover">
            <Cover alt={title}
                cover={cover}
                mainIntersected={mainIntersected}
                swatch={swatch}
            />
        </div>
    )
}

export default ArticleHeaderCover