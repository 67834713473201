import React from "react"
import { Route, Router, Switch } from "react-router-dom"
import { createBrowserHistory } from "history"
import useGoogleAnalytics from "hooks/useGoogleAnalytics"
import ScrollToTop from "components/ScrollToTop/ScrollToTop"
import Home from "components/Home/Home"
import Post from "components/Post/Post"

import alk from "articles/alk.json"
import almouatina from "articles/almouatina.json"
import gymbox from "articles/gymbox.json"

const postsData = [
    alk,
    almouatina,
    gymbox
]

const App = () => {
    const history = createBrowserHistory()

    useGoogleAnalytics(history)

    return (
        <Router history={history}>
            <ScrollToTop>
                <div className="App">
                    <Switch>
                        <Route exact
                            path="/"
                            render={() => <Home postsData={postsData} />}
                        />

                        <Route path="/:subdirectory"
                            render={props => <Post {...props} postsData={postsData} />}
                        />
                    </Switch>
                </div>
            </ScrollToTop>
        </Router>
    )
}

export default App