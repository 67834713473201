import React, { Fragment, useState } from "react"
import VideoPlaceholder from "./VideoPlaceholder/VideoPlaceholder"
import VideoLazy from "./VideoLazy/VideoLazy"
import VideoFull from "./VideoFull/VideoFull"

const Video = ({ swatch, video }) => {
    const [videoLoaded, setVideoLoaded] = useState(false)
    const [videoLazyLoaded, setVideoLazyLoaded] = useState(false)

    return (
        <Fragment>
            <VideoPlaceholder aspectRatio={video?.aspectRatio}
                swatch={swatch}
            />

            <VideoLazy setVideoLazyLoaded={setVideoLazyLoaded}
                video={video}
                videoLazyLoaded={videoLazyLoaded}
            />

            <VideoFull video={video}
                videoLazyLoaded={videoLazyLoaded}
                videoLoaded={videoLoaded}
                setVideoLoaded={setVideoLoaded}
            />
        </Fragment>
    )
}

export default Video