import React from "react"
import "./HomePostContent.scss"
import HomePostContentType from "./HomePostContentType/HomePostContentType"
import HomePostContentTitle from "./HomePostContentTitle/HomePostContentTitle"

const HomePostContent = ({ swatch, title, type }) => {
    return (
        <div className="Home__post__content">
            <HomePostContentType swatch={swatch}
                type={type}
            />

            <HomePostContentTitle title={title} />
        </div>
    )
}

export default HomePostContent