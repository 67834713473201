import React, { useRef, useState } from "react"
// import { useEffect } from "react"
import "./HomePost.scss"
import { Link } from "react-router-dom"
import HomePostPlaceholder from "./HomePostPlaceholder/HomePostPlaceholder"
import HomePostLazy from "./HomePostLazy/HomePostLazy"
import HomePostFull from "./HomePostFull/HomePostFull"
import HomePostFade from "./HomePostFade/HomePostFade"
import HomePostContent from "./HomePostContent/HomePostContent"
// import useIntersectionObserver from "hooks/useIntersectionObserver"

const HomePost = ({ incrementNumberOfIntersectedPosts, post }) => {
    const [coverLoaded, setCoverLoaded] = useState(false)
    const [coverLazyLoaded, setCoverLazyLoaded] = useState(false)

    const ref = useRef()

    // const intersected = useIntersectionObserver(ref)

    // useEffect(() => {
    //     if (intersected) incrementNumberOfIntersectedPosts()
    // }, [intersected])

    return (
        <Link className="Home__post" ref={ref} to={post.subdirectory}>
            <HomePostPlaceholder swatch={post.swatch} />

            <HomePostLazy cover={post.cover}
                coverLazyLoaded={coverLazyLoaded}
                setCoverLazyLoaded={setCoverLazyLoaded}
            />

            <HomePostFull cover={post.cover}
                coverLazyLoaded={coverLazyLoaded}
                coverLoaded={coverLoaded}
                setCoverLoaded={setCoverLoaded}
            />

            <HomePostFade swatch={post.swatch} />

            <HomePostContent swatch={post.swatch}
                title={post.title}
                type={post.type}
            />
        </Link>
    )
}

export default HomePost