import React from "react"

const StrikeThrough = ({ text, textDecorationColor }) => {
    return (
        <s className="StrikeThrough"
            style={{ textDecorationColor }}>
            {text}
        </s>
    )
}

export default StrikeThrough