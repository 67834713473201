import React from "react"

const Underline = ({ text, textDecorationColor }) => {
    return (
        <u className="Underline"
            style={{ textDecorationColor }}>
            {text}
        </u>
    )
}

export default Underline