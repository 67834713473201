import React from "react"
import "./DropCap.scss"

const DropCap = ({ letter, swatch }) => {
    return (
        <span className="DropCap"
            style={{ backgroundImage: `linear-gradient(to right, ${swatch.lightVibrant}, ${swatch.vibrant})` }}>
            {letter}
        </span>
    )
}

export default DropCap