import React from "react"
// import { useEffect, useState } from "react"
import "./Post.scss"
// import axios from "axios"
import Logo from "components/Logo/Logo"
import PageLoading from "components/PageLoading/PageLoading"
import PageNotFound from "components/PageNotFound/PageNotFound"
import PostHead from "./PostHead/PostHead"
import PostFooter from "./PostFooter/PostFooter"
import PostMain from "./PostMain/PostMain"
import PostThemedSelection from "./PostThemedSelection/PostThemedSelection"

const Post = ({
    // Post
    match,
    // Editor
    editor,
    handleEditHeading,
    handleEditFigureCaption,
    handleSelection,
    project,
    postsData
}) => {
    // console.log(postsData)
    // console.log(postsData.filter(p => p.subdirectory === match.params.subdirectory)[0])
    // const [post, setPost] = useState(postsData.filter(p => p.subdirectory === match.params.subdirectory)[0])
    // const [suggestedPosts, setSuggestedPosts] = useState(null)

    // useEffect(() => {
    //     if (!editor) return

    //     setPost(project)
    //     setSuggestedPosts([])
    // }, [project])

    // useEffect(() => {
    //     if (editor) return

    //     axios.get(`/post/${match.params.subdirectory}?suggest=true`)
    //         .then(({ data }) => {
    //             setPost(data.post)
    //             setSuggestedPosts(data.suggestedPosts || [])
    //         })
    // }, [match.params.subdirectory])

    // if (post === null || suggestedPosts === null) return <PageLoading />

    console.log(postsData)
    console.log(match.params.subdirectory)

    const post = postsData.filter(p => p.subdirectory === match.params.subdirectory)[0]

    const suggestedPosts = postsData.slice(0, 4)

    console.log(post)

    if (!post) return <PageLoading />

    if (post?.type) return (
        <div className="Post">
            <PostHead darkVibrant={post.swatch.darkVibrant}
                description={post.description}
                title={post.title}
            />

            <Logo className="Logo--fixed" />

            <PostMain post={post}
                // Editor
                editor={editor}
                handleEditHeading={handleEditHeading}
                handleEditFigureCaption={handleEditFigureCaption}
                handleSelection={handleSelection}
            />

            <PostThemedSelection theme={post.swatch.vibrant} />

            <PostFooter subdirectoryHash={post.subdirectory_hash}
                suggestedPosts={suggestedPosts}
                swatch={post.swatch}
                type={post.type}
            />
        </div>
    )

    return <PageNotFound />
}

export default Post