import { useEffect } from "react"
import ReactGA from "react-ga"

const useGoogleAnalytics = history => {
    useEffect(() => {
        ReactGA.pageview(history.location.pathname)
    }, [history.location.pathname])

    ReactGA.initialize("G-BB53Y7VWBJ")
    history.listen(location => {
        ReactGA.pageview(location.pathname)
    })
}

export default useGoogleAnalytics