import React, { useRef } from "react"
import "./Figure.scss"
import FigureCaption from "./FigureCaption/FigureCaption"
import FigureImage from "./FigureImage/FigureImage"
import FigureVideo from "./FigureVideo/FigureVideo"
import useIntersectionObserver from "hooks/useIntersectionObserver"

const Figure = ({
    caption,
    image,
    swatch,
    type,
    video,
    // Editor
    editor,
    handleEditFigureCaption,
    index
}) => {
    const ref = useRef()

    const intersected = useIntersectionObserver(ref)

    return (
        <figure className={`Figure intersected--${intersected}`} ref={ref}>
            {
                video ?
                (
                    <FigureVideo swatch={swatch}
                        video={video}
                    />
                ) :
                (
                    <FigureImage caption={caption}
                        image={image}
                        swatch={swatch}
                    />
                )
            }

            <FigureCaption caption={caption}
                // Editor
                editor={editor}
                handleEditFigureCaption={handleEditFigureCaption}
                index={index}
            />
        </figure>
    )
}

export default Figure