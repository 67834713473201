import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import App from "./App"
import ScrollContextProvider from "contexts/ScrollContext/ScrollContextProvider"
import WindowDimensionsContextProvider from "contexts/WindowDimensionsContext/WindowDimensionsContextProvider"

const element = (
    <ScrollContextProvider>
        <WindowDimensionsContextProvider>
            <App />
        </WindowDimensionsContextProvider>
    </ScrollContextProvider>
)

const container = document.getElementById("Different")

ReactDOM.render(element, container)