import React from "react"
import Article from "./Article/Article"

const PostMain = ({
    post,
    // Editor
    editor,
    handleEditHeading,
    handleEditFigureCaption,
    handleSelection
}) => {
    if (post.type === "article") return (
        <Article article={post}
            // Editor
            editor={editor}
            handleEditHeading={handleEditHeading}
            handleEditFigureCaption={handleEditFigureCaption}
            handleSelection={handleSelection}
        />
    )

    return null
}

export default PostMain