import React, { Fragment } from "react"
import "./PageNotFound.scss"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import Logo from "components/Logo/Logo"

const PageNotFound = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Nothing here - Different</title>
                <meta name="theme-color" content="#000000" />
            </Helmet>

            <Logo className="Logo--fixed" />

            <div className="PageNotFound">
                <h1>Nothing here</h1>

                <Link to="/">
                    العودة إلى الصفحة الرئيسية
                </Link>
            </div>
        </Fragment>
    )
}

export default PageNotFound