import React from "react"
import "./CoverFull.scss"
import getOnTheFlyTransformURL from "functions/getOnTheFlyTransformURL"

const CoverFull = ({ alt, cover, coverLazyLoaded, coverLoaded, setCoverLoaded }) => {
    if (!coverLazyLoaded) return null

    if (cover.currentGen.mime === "image/gif") return (
        <picture className={`Cover__full loaded--${coverLoaded}`}>
            {/* Landscape */}
            <source srcSet={cover.nextGen.landscapeSource.full}
                media="(orientation: landscape)"
                type={cover.nextGen.mime}
            />

            <source srcSet={cover.currentGen.landscapeSource.full}
                media="(orientation: landscape)"
                type={cover.currentGen.mime}
            />

            {/* Portrait */}
            <source srcSet={cover.nextGen.portraitSource.full}
                media="(orientation: portrait)"
                type={cover.nextGen.mime}
            />

            <source srcSet={cover.currentGen.portraitSource.full}
                media="(orientation: portrait)"
                type={cover.currentGen.mime}
            />

            <img alt={alt}
                onLoad={() => setCoverLoaded(true)}
                src={cover.currentGen.landscapeSource.full}
            />
        </picture>
    )

    return (
        <picture className={`Cover__full loaded--${coverLoaded}`}>
            {/* Landscape for mobile. */}
            <source srcSet={getOnTheFlyTransformURL(cover.nextGen.landscapeSource.full, 850)}
                media="(orientation: landscape) and (max-width: 850px)"
                type={cover.nextGen.mime}
            />

            <source srcSet={getOnTheFlyTransformURL(cover.currentGen.landscapeSource.full, 850)}
                media="(orientation: landscape) and (max-width: 850px)"
                type={cover.currentGen.mime}
            />

            {/* Landscape for laptops. */}
            <source srcSet={getOnTheFlyTransformURL(cover.nextGen.landscapeSource.full, 1366)}
                media="(orientation: landscape) and (max-width: 1366px)"
                type={cover.nextGen.mime}
            />

            <source srcSet={getOnTheFlyTransformURL(cover.currentGen.landscapeSource.full, 1366)}
                media="(orientation: landscape) and (max-width: 1366px)"
                type={cover.currentGen.mime}
            />

            {/* Landscape for desktops. */}
            <source srcSet={getOnTheFlyTransformURL(cover.nextGen.landscapeSource.full, 1920)}
                media="(orientation: landscape) and (max-width: 1920px)"
                type={cover.nextGen.mime}
            />

            <source srcSet={getOnTheFlyTransformURL(cover.currentGen.landscapeSource.full, 1920)}
                media="(orientation: landscape) and (max-width: 1920px)"
                type={cover.currentGen.mime}
            />

            {/* Landscape for high resolution displays. */}
            <source srcSet={cover.nextGen.landscapeSource.full}
                media="(orientation: landscape) and (min-width: 1920px)"
                type={cover.nextGen.mime}
            />

            <source srcSet={cover.currentGen.landscapeSource.full}
                media="(orientation: landscape) and (min-width: 1920px)"
                type={cover.currentGen.mime}
            />

            {/* Portrait for mobile. */}
            <source srcSet={getOnTheFlyTransformURL(cover.nextGen.portraitSource.full, 450)}
                media="(orientation: portrait) and (max-width: 450px)"
                type={cover.nextGen.mime}
            />

            <source srcSet={getOnTheFlyTransformURL(cover.currentGen.portraitSource.full, 450)}
                media="(orientation: portrait) and (max-width: 450px)"
                type={cover.currentGen.mime}
            />

            {/* Portrait for tablets. */}
            <source srcSet={getOnTheFlyTransformURL(cover.nextGen.portraitSource.full, 1024)}
                media="(orientation: portrait) and (max-width: 1024px)"
                type={cover.nextGen.mime}
            />

            <source srcSet={getOnTheFlyTransformURL(cover.currentGen.portraitSource.full, 1024)}
                media="(orientation: portrait) and (max-width: 1024px)"
                type={cover.currentGen.mime}
            />

            {/* Portrait monitors. */}
            <source srcSet={cover.nextGen.portraitSource.full}
                media="(orientation: portrait) and (min-width: 1024px)"
                type={cover.nextGen.mime}
            />

            <source srcSet={cover.currentGen.portraitSource.full}
                media="(orientation: portrait) and (min-width: 1024px)"
                type={cover.currentGen.mime}
            />

            {/* Internet explorer probably 😂 */}
            <img alt={alt}
                onLoad={() => setCoverLoaded(true)}
                src={cover.currentGen.landscapeSource.full}
            />
        </picture>
    )
}

export default CoverFull