import React from "react"
import "./HomePostFull.scss"
import getOnTheFlyTransformURL from "functions/getOnTheFlyTransformURL"

const HomePostFull = ({ cover, coverLazyLoaded, coverLoaded, setCoverLoaded }) => {
    if (!coverLazyLoaded) return null

    if (cover.currentGen.mime === "image/gif") return (
        <picture className={`Home__post__full loaded--${coverLoaded}`}>
            <source srcSet={cover.nextGen.portraitSource.full}
                type={cover.nextGen.mime}
            />

            <source srcSet={cover.currentGen.portraitSource.full}
                type={cover.currentGen.mime}
            />

            <img alt=""
                onLoad={() => setCoverLoaded(true)}
                src={cover.currentGen.portraitSource.full}
            />
        </picture>
    )

    return (
        <picture className={`Home__post__full loaded--${coverLoaded}`}>
            <source srcSet={getOnTheFlyTransformURL(cover.nextGen.portraitSource.full, 450)}
                type={cover.nextGen.mime}
            />

            <source srcSet={getOnTheFlyTransformURL(cover.currentGen.portraitSource.full, 450)}
                type={cover.currentGen.mime}
            />

            <img alt=""
                onLoad={() => setCoverLoaded(true)}
                src={getOnTheFlyTransformURL(cover.currentGen.portraitSource.full, 450)}
            />
        </picture>
    )
}

export default HomePostFull