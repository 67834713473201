import React from "react"
import "./CoverPlaceholder.scss"

const CoverPlaceholder = ({ swatch }) => {
    if (!swatch) return null

    const backgroundImage = `
        radial-gradient(ellipse at top, ${swatch.lightVibrant}, ${swatch.vibrant}, ${swatch.darkVibrant}),
        radial-gradient(ellipse at bottom, ${swatch.lightMuted}, ${swatch.muted}, ${swatch.darkMuted})  
    `

    return (
        <div className="Cover__placeholder"
            style={{ backgroundImage }}>
        </div>
    )
}

export default CoverPlaceholder