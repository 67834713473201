import React, { Fragment } from "react"
import "./ArticleMain.scss"
import useBlocks from "components/Post/PostMain/Blocks/useBlocks"

const ArticleMain = ({ blocks, mainRef, swatch }) => {
    const mappedBlocks = useBlocks(
        blocks,
        swatch
    )

    return (
        <Fragment>
            <div className="Article__main--object-observer-fix"></div>

            <main className="Article__main" ref={mainRef}>
                {mappedBlocks}
            </main>
        </Fragment>
    )
}

export default ArticleMain