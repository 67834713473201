import React from "react"
import "./Anchor.scss"

const Anchor = ({ color, href, text }) => {
    return (
        <a className="Anchor"
            href={href}
            rel="noopener nofollow noreferrer"
            style={{ color }}
            target="_blank">
            {text}
        </a>
    )
}

export default Anchor