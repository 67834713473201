import React from "react"
import SocialLinks from "components/SocialLinks/SocialLinks"
// import PostFooterSuggestedPosts from "./PostFooterSuggestedPosts/PostFooterSuggestedPosts"
// import PostFooterURLCopy from "./PostFooterURLCopy/PostFooterURLCopy"

const PostFooter = ({ subdirectoryHash, suggestedPosts, swatch, type }) => {
    if (type === "story") return null

    return (
        <footer className="Post__footer">
            {/* <PostFooterURLCopy subdirectoryHash={subdirectoryHash} swatch={swatch} /> */}

            {/* <PostFooterSuggestedPosts suggestedPosts={suggestedPosts} /> */}

            <SocialLinks />
        </footer>
    )
}

export default PostFooter