import React, { Fragment } from "react"
import "./PageLoading.scss"
import { Helmet } from "react-helmet"
import Loader from "components/Loader/Loader"
import Logo from "components/Logo/Logo"

const PageLoading = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Loading - Different</title>
                <meta name="theme-color" content="#000000" />
            </Helmet>

            <Logo className="Logo--fixed" />

            <div className="PageLoading">
                <Loader className="PageLoading__loader"
                    visible={true}
                />
            </div>
        </Fragment>
    )
}

export default PageLoading