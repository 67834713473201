import React from "react"
import "./VideoFull.scss"

const VideoFull = ({
    video,
    videoLazyLoaded,
    videoLoaded,
    setVideoLoaded
}) => {
    if (!videoLazyLoaded) return null

    return (
        <video autoPlay={true}
            className={`Video__full loaded--${videoLoaded}`}
            loop={true}
            muted={true}
            onCanPlayThrough={() => setVideoLoaded(true)}>
            {/* <source src={video.nextGen.source.full}
                type={video.nextGen.mime}
            /> */}

            <source src={video.currentGen.source.full}
                type={video.currentGen.mime}
            />
        </video>
    )
}

export default VideoFull