import React from "react"
import "./FigureImage.scss"
import Img from "components/Img/Img"

const FigureImage = ({
    caption,
    image,
    swatch
}) => {
    return (
        <div className="Figure__image">
            <Img alt={caption}
                image={image}
                swatch={swatch}
            />
        </div>
    )
}

export default FigureImage