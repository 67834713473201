import React, { useRef } from "react"
import "./Quote.scss"
import QuoteContent from "./QuoteContent/QuoteContent"
import QuoteAuthorImage from "./QuoteAuthorImage/QuoteAuthorImage"
import useIntersectionObserver from "hooks/useIntersectionObserver"

const Quote = ({ author, children, cite, citeURL, image, swatch }) => {
    const ref = useRef()

    const intersected = useIntersectionObserver(ref)

    return (
        <blockquote className={`Quote intersected--${intersected}`}
            cite={citeURL ? citeURL : undefined}
            ref={ref}>
            <QuoteAuthorImage author={author}
                image={image}
                swatch={swatch}
            />

            <QuoteContent author={author}
                cite={cite}
                citeURL={citeURL}
                quote={children}
                swatch={swatch}
            />
        </blockquote>
    )
}

export default Quote