import React from "react"
import "./QuoteAuthorImageBackground.scss"

const QuoteAuthorImageBackground = ({ swatch }) => {
    return (
        <svg className="Quote__author-image__background" viewBox="0 0 160 90">
            <defs>
                <linearGradient id="quote-image-background-gradient">
                    <stop stopColor={swatch.lightVibrant} offset="0%"/>
                    <stop stopColor={swatch.vibrant} offset="100%"/>
                </linearGradient>
            </defs>

            <circle cx="0" cy="20%" r="40" />
        </svg>
    )
}

export default QuoteAuthorImageBackground