import React, { useRef } from "react"
import "./Paragraph.scss"
import useIntersectionObserver from "hooks/useIntersectionObserver"
import Anchor from "./TextSpans/Anchor/Anchor"
import Bold from "./TextSpans/Bold/Bold"
import Code from "./TextSpans/Code/Code"
import Italic from "./TextSpans/Italic/Italic"
import Highlight from "./TextSpans/Highlight/Highlight"
import Span from "./TextSpans/Span/Span"
import StrikeThrough from "./TextSpans/StrikeThrough/StrikeThrough"
import Underline from "./TextSpans/Underline/Underline"
import DropCap from "./TextSpans/DropCap/DropCap"

const Paragraph = ({
    swatch,
    children,
    // Editor
    editor,
    handleSelection,
    index
}) => {
    const ref = useRef()

    const intersected = useIntersectionObserver(ref)

    const text = children.text

    const textMap = children.textSpans.map((textSpan, index) => {
        const { endOffset, modifiers, startOffset } = textSpan

        if (modifiers.length === 0) return (
            <Span key={index}
                text={text.substring(startOffset, endOffset)}
            />
        )

        else if (modifiers.includes("drop-cap")) return (
            <DropCap key={index}
                letter={text.substring(startOffset, endOffset)}
                swatch={swatch}
            />
        )

        else if (modifiers.includes("code")) return (
            <Code backgroundColor={swatch.darkVibrant}
                key={index}
                text={text.substring(startOffset, endOffset)}
            />
        )

        else if (modifiers.includes("link")) return (
            <Anchor color={swatch.lightVibrant}
                href="https://google.com"
                key={index}
                text={text.substring(startOffset, endOffset)}
            />
        )

        else if (modifiers.includes("italic")) return (
            <Italic key={index}
                text={text.substring(startOffset, endOffset)}
            />
        )

        else if (modifiers.includes("bold")) return (
            <Bold key={index}
                text={text.substring(startOffset, endOffset)}
            />
        )

        else if (modifiers.includes("underlined")) return (
            <Underline key={index}
                text={text.substring(startOffset, endOffset)}
                textDecorationColor={swatch.lightVibrant}
            />
        )

        else if (modifiers.includes("strike-through")) return (
            <StrikeThrough key={index}
                text={text.substring(startOffset, endOffset)}
                textDecorationColor={swatch.lightVibrant}
            />
        )

        else if (modifiers.includes("highlighted")) return (
            <Highlight key={index}
                swatch={swatch}
                text={text.substring(startOffset, endOffset)}
            />
        )

        else return null
    })

    return (
        <p className={`Paragraph intersected--${intersected}`}
            ref={ref}
            // Editor
            contentEditable={editor || false}
            data-index={editor ? index : null}
            onSelect={handleSelection}
            suppressContentEditableWarning={editor || false}>
            {textMap}
        </p>
    )
}

export default Paragraph