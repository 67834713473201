import React from "react"
import "./Bold.scss"

const Bold = ({ text }) => {
    return (
        <em className="Bold">
            {text}
        </em>
    )
}

export default Bold