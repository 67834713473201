import React, { useRef, useState, useEffect } from "react"
import "./PreformattedCode.scss"
import useIntersectionObserver from "hooks/useIntersectionObserver"

const PreformattedCode = ({ children, swatch }) => {
    const [height, setHeight] = useState(0)

    const ref = useRef()

    useEffect(() => {
        setHeight(ref.current.offsetHeight)
    }, [])

    const intersected = useIntersectionObserver(ref)

    let operatingSystem = null
    if (window.navigator.userAgent.indexOf("Windows") !== -1) operatingSystem = "windows"
    else if (window.navigator.userAgent.indexOf("Mac") !== -1) operatingSystem = "mac"
    else if (window.navigator.userAgent.indexOf("Linux") !== -1) operatingSystem = "linux"

    return (
        <pre className={`PreformattedCode intersected--${intersected}`}
            style={{ height }}>
            <code className={`PreformattedCode__code ${operatingSystem}`}
                ref={ref}
                style={{ backgroundColor: swatch.muted }}>
                {
                    children.split("").map((character, index) => {
                        if (character === " ") return (
                            <span className="PreformattedCode__code__whitespace"
                                key={index}>
                                {character}
                            </span>
                        )
                        return (
                            <span className="PreformattedCode__code__character" key={index}>
                                {character}
                            </span>
                        )
                    })
                }
            </code>
        </pre>
    )
}

export default PreformattedCode