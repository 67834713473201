import React from "react"
import "./Logo.scss"
import { Link } from "react-router-dom"

const Logo = ({ className }) => {
    return (
        <Link aria-label="رابط إلى الصفحة الرئيسية"
            className={`Logo ${className ? className : ""}`}
            to="/">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFF">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M9 19l1.41-1.41L5.83 13H22v-2H5.83l4.59-4.59L9 5l-7 7 7 7z" />
            </svg>
        </Link>
    )
}

export default Logo