import React from "react"
import "./Loader.scss"

const Loader = ({ className, visible }) => {
    if (!visible) return null

    return (
        <svg className={`Loader ${className}`} viewBox="0 0 50 50">
            <defs>
                <linearGradient id="loader-gradient">
                    <stop offset="0%" stopColor="white" />
                    <stop offset="100%" stopColor="transparent" />
                </linearGradient>
            </defs>

            <circle cx="25" cy="25" r="23" />
        </svg>
    )
}

export default Loader