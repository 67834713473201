import React from "react"

const Span = ({ text }) => {
    return (
        <span className="Span">
            {text}
        </span>
    )
}

export default Span