import React from "react"
import "./ArticleHeaderFade.scss"
import { animated, useTransition } from "react-spring"

const ArticleHeaderFade = ({ mainIntersected, swatch }) => {
    const transitions = useTransition(
        mainIntersected,
        null,
        {
            from: { opacity: 0, transform: "translateY(10rem)" },
            enter: { opacity: 1, transform: "translateY(0)" },
            leave: { opacity: 0, transform: "translateY(10rem)" }
        }
    )

    return transitions.map(({ item, key, props }) => {
        if (!item) return null

        return (
            <animated.div className="Article__header__fade"
                key={key}
                style={{
                    background: `linear-gradient(
                        to top,
                        black 1%,
                        ${swatch.vibrant.replace("rgb", "rgba").replace(")", "").concat(", 0.5)")} 33%,
                        ${swatch.muted.replace("rgb", "rgba").replace(")", "").concat(", 0.5)")} 66%,
                        rgba(0, 0, 0, 0) 100%
                    )`,
                    ...props
                }}>
            </animated.div>
        )
    })
}

export default ArticleHeaderFade