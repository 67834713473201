import React from "react"
import "./Code.scss"

const Code = ({ backgroundColor, text }) => {
    return (
        <code className="Code" style={{ backgroundColor }}>
            {text}
        </code>
    )
}

export default Code