import React, { Fragment } from "react"
import "./QuoteContent.scss"

const QuoteContent = ({ author, cite, citeURL, quote, swatch }) => {
    return (
        <div className="Quote__content">
            <p className="Quote__content__quote">
                <span style={{ color: swatch.vibrant }}>“</span>
                {quote}
                <span style={{ color: swatch.vibrant }}>”</span>
            </p>

            <cite className="Quote__content__cite"
                style={{ backgroundImage: `linear-gradient(to right, ${swatch.lightVibrant}, ${swatch.vibrant})` }}>
                {author}

                {
                    cite ?
                    (
                        <Fragment>
                            &nbsp;

                            <a href={citeURL}
                                style={{ textDecorationColor: swatch.vibrant }}
                                rel="noopener noreferrer nofollow"
                                target="_blank">
                                {cite}
                            </a>
                        </Fragment>
                    ) :
                    null
                }
            </cite>
        </div>
    )
}

export default QuoteContent