import React from "react"
import "./Italic.scss"

const Italic = ({ text }) => {
    return (
        <em className="Italic">
            {text}
        </em>
    )
}

export default Italic